import {
    ColDef,
    ValueFormatterParams,
    ICellRendererParams,
    ValueGetterParams,
} from 'ag-grid-community';
import styles from '../../SyncArticle/index.module.scss';
import { AgGridReact } from 'ag-grid-react';
import { Button, Pagination, Tooltip, message } from 'antd';
import moment from 'moment';
import React, { useCallback, useMemo } from 'react';
import useFetch from '../../../utils/Hooks/useFetch';
import { getPushHistory, cancelPushTask } from '../../../service/requests/PushCenter';
import './index.less';
import { showError } from '../../RecommendationComponent/util';
const NEWSPAGESIZE = 15;

const TooltipCell = ({ text }: { text: string }) => (
    <Tooltip title={text} placement="topLeft">
        <span>{text}</span>
    </Tooltip>
);

export default function PushHistory() {
    const [currentPage, setCurrentPage] = React.useState(1);
    const [pageSize, setPageSize] = React.useState(NEWSPAGESIZE);
    const [fetchData, setParams, refreshData] = useFetch({
        interfaces: getPushHistory,
        initialParams: { page_size: pageSize, current_page: 1 },
    });
    const colDefs = useMemo<ColDef[]>(() => {
        return [
            {
                headerName: '发送对象',
                valueGetter: (params: ValueGetterParams) => {
                    let names = [];
                    if (params.data.users?.length) {
                        for (let user of params.data.users) {
                            names.push(user.name);
                        }
                    } else {
                        return '所有人';
                    }
                    return names.join('，');
                },
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '状态',
                field: 'status',
                width: 120,
                cellRenderer: (params: ICellRendererParams) => {
                    const value = params.data.push_task_status.desc;
                    switch (params.data.push_task_status.code) {
                        case 1:
                            return <span className="status-waiting">{value}</span>;
                        case 2:
                            return <span className="status-pushing">{value}</span>;
                        case 3:
                            return <span className="status-pushed">{value}</span>;
                        case 4:
                            return value;
                        case 5:
                            return value;
                        default:
                            return <span className="status-cancelled">{value}</span>;
                    }
                },
            },
            {
                headerName: '发送时间',
                valueGetter: (params: ValueGetterParams) => {
                    return params.data.push_at !== 0 ? params.data.push_at : params.data.created_at;
                },
                valueFormatter: (params: ValueFormatterParams) => {
                    if (params.value) {
                        return moment(params.value).format('YYYY-MM-DD HH:mm:ss');
                    }
                    return '';
                },
            },
            {
                headerName: '标题',
                field: 'title',
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '内容',
                field: 'body',
                flex: 1,
                cellRenderer: (params: ICellRendererParams) => <TooltipCell text={params.value} />,
            },
            {
                headerName: '操作',
                pinned: 'right',
                resizable: false,
                width: 120,
                cellRenderer: (params: ICellRendererParams) => {
                    if (params.data?.push_task_status?.code === 1) {
                        return (
                            <Button
                                type="link"
                                danger
                                onClick={() => {
                                    cancelPushTask({ task_id: params.data.id })
                                        .then(() => {
                                            message.success('任务已取消');
                                        }, showError)
                                        .finally(() => {
                                            refreshData();
                                        });
                                }}
                            >
                                取消
                            </Button>
                        );
                    }
                    return null;
                },
            },
        ];
    }, [refreshData]);

    return (
        <>
            <div className="ag-theme-alpine" style={{ marginTop: '16px', width: '100%' }}>
                <AgGridReact
                    animateRows
                    columnDefs={colDefs}
                    defaultColDef={{ resizable: true, width: 160 }}
                    rowData={fetchData.data?.list ?? []}
                    domLayout="autoHeight"
                    enableCellTextSelection
                    ensureDomOrder
                />
                {
                    // <Pagination
                    //     onChange={handlePaginationChange}
                    //     total={fetchData.data?.total_count ?? 0}
                    //     style={{
                    //         display: 'flex',
                    //         justifyContent: 'flex-end',
                    //         marginTop: 30,
                    //     }}
                    //     pageSize={NEWSPAGESIZE}
                    //     defaultCurrent={1}
                    //     showSizeChanger={false}
                    // />
                    <Pagination
                        className={styles.page}
                        total={fetchData.data?.total_count ?? 0}
                        showTotal={(total, range) => {
                            return `总条数：${total}`;
                        }}
                        onShowSizeChange={(current, size) => {
                            setPageSize(size);
                        }}
                        pageSize={pageSize}
                        onChange={(page, pageSize) => {
                            setParams({ current_page: page, page_size: pageSize });
                            setPageSize(pageSize);
                            setCurrentPage(page);
                        }}
                        showSizeChanger
                        current={currentPage}
                    />
                }
            </div>
        </>
    );
}
